import React, { useState } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import SEO from "../components/seo/seo";
import HeroChevron from "../components/hero/hero-chevron";
import Breadcrumb from "../components/breadcrumb/breadcrumb";

// Font Awesome icons
import Icon from "../components/custom-widgets/icon";
import getHeroImgVariables from "../helpers/getHeroImgVariables";

const NotFoundPage = () => {
  const [query, setQuery] = useState("");
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/404/hero-404-05042023-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/404/hero-404-05042023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/404/hero-404-05042023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/404/hero-404-05042023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/404/hero-404-05042023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/404/hero-404-05042023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/404/hero-404-05042023-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const seoData = {
    title: "Error 404",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Error 404"
      },
      {
        name: "description",
        content: "Error 404"
      },
      {
        property: "og:title",
        content: "Error 404"
      },
      {
        property: "og:description",
        content: "Error 404"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/404"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-404-05042023.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "404-login-hero",
    ...getHeroImgVariables(imgData),
    altText: "Walt the Vault floating in space wearing an astronaut suit.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Oops! Even WaFd Walt gets lost sometimes"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "404"
    }
  ];

  const helpSectionData = [
    {
      url: "/",
      icon: "home",
      content: [
        <Link className="stretched-link" to="/"></Link>,
        <p className="font-weight-bold">WaFd Bank Home</p>,
        <p>Proudly serving our customers since 1917</p>
      ]
    },
    {
      icon: "map-marker-alt",
      content: [
        <Link className="stretched-link" to="/locations"></Link>,
        <p className="font-weight-bold">Find Local Branch</p>,
        <p>Serving 9 states with access to over 40,000 fee-free ATMs</p>
      ]
    },
    {
      icon: "mobile-alt",
      content: [
        <p>
          <a href="tel:800-324-9375" className="text-black font-weight-bold text-decoration-none">
            800-324-9375
          </a>
        </p>,
        <p>
          <Link to="/about-us/contact-us">
            Customer Service is standing by to help with any banking need or question
          </Link>
        </p>
      ]
    },
    {
      colClass: "mb-md-0",
      icon: "desktop",
      content: [
        <p>
          <Link className="font-weight-bold text-black text-decoration-none" to="/personal-banking">
            Personal
          </Link>
        </p>,
        <a
          href="https://online.wafdbank.com"
          rel="noopener noreferrer"
          target="_blank"
          id="page-404-olb-sign-in-link"
          class="olb-sign-in-link"
        >
          Sign In to Personal Banking
        </a>
      ]
    },
    {
      colClass: "mb-md-0",
      icon: "user",
      content: [
        <p>
          <Link className="font-weight-bold text-black text-decoration-none" to="/business-banking/small-business">
            Small Business
          </Link>
        </p>,
        <a
          href="https://commercial.washingtonfederal.com/smallbusiness/auth"
          className="d-block"
          rel="noopener noreferrer"
          target="_blank"
        >
          Treasury Express
        </a>
      ]
    },
    {
      colClass: "mb-md-0",
      icon: "building",
      content: [
        <p>
          <Link className="font-weight-bold text-black text-decoration-none" to="/commercial-banking">
            Commercial
          </Link>
        </p>,
        <a href="https://commercial.washingtonfederal.com" rel="noopener noreferrer" target="_blank">
          Treasury Prime
        </a>
      ]
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="bg-light border-y mt-5">
        <div className="container text-center">
          <h1>Can we help you find something?</h1>

          <form
            className="offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-4 col-lg-4"
            onSubmit={(event) => {
              event.preventDefault();
              navigate(`/customer-service/search?addsearch=${query}`);
              setQuery("");
            }}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="How can we help?"
                aria-label="site search text"
                aria-describedby="search-input"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Link
                className="btn-primary"
                type="button"
                to={`/customer-service/search?addsearch=${query}`}
                id="search-button"
              >
                <Icon name="search" class="fa m-2" />
                <span className="sr-only">Search</span>
              </Link>
            </div>
          </form>
        </div>
      </section>

      <section className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          {helpSectionData.map((data) => {
            return (
              <div className={`col mb-5 ${data.colClass}`}>
                <div className="row">
                  <div className="col-3 text-center">
                    <Icon class="fa-3x text-black" name={data.icon} />
                  </div>

                  <div className="col">
                    {data.content.map((content) => {
                      return content;
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default NotFoundPage;
